import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"

export const Button = (props) => {
	const {
		className = "",
		id,
		onClick,
		Icon,
		label,
		disabled,
		customStyle,
		type,
		secondaryLabel,
		isIconRight,
		isLarge,
		size,
		textPosition,
		iconRelative,
		styleDisabled,
		linkUrl,
		customClass,
		customStyleText,
		changeVal,
	} = props

	const [isClickable, setIsClickable] = useState(false)

	useEffect(() => {
		if (type !== "loading" && !disabled) {
			return
		}

		const timer = setTimeout(() => setIsClickable(true), 3000)
		return () => clearTimeout(timer)
	}, [isClickable])

	return (
		<>
			{linkUrl ? (
				<a
					className={`button linkButton ${type ?? "primary"} ${
						size ?? "small"
					} ${textPosition ?? "textCenter"} ${
						!isClickable && type === "loading" && !disabled
							? "is-loading"
							: ""
					} ${isIconRight ? "iconRight" : ""} ${
						isLarge ? "isLarge" : ""
					} ${iconRelative ? "iconRelative" : ""} ${
						styleDisabled || disabled ? "disabled" : ""
					} ${className}`}
					onClick={
						type === "loading" && isClickable && !disabled
							? onClick
							: onClick
					}
					disabled={disabled && !styleDisabled}
					style={customStyle}
					href={linkUrl}
					target="_blank"
					rel="noreferrer"
					id={id ?? id}
				>
					<div>
						{Icon}
						<div className="button-text">
							{type === "loading" && !isClickable && !disabled
								? secondaryLabel
								: label}
						</div>
					</div>
				</a>
			) : (
				<button
					type="button"
					className={`button ${type ?? "primary"} ${
						size ?? "small"
					} ${textPosition ?? "textCenter"} ${
						!isClickable && type === "loading" && !disabled
							? "is-loading"
							: ""
					} ${isIconRight ? "iconRight" : ""} ${
						isLarge ? "isLarge" : ""
					} ${
						iconRelative
							? `iconRelative ${changeVal ? "changeVal" : ""}`
							: ""
					} ${styleDisabled || disabled ? "disabled" : ""} ${
						customClass ? customClass : ""
					} ${className}`}
					onClick={
						type === "loading" && isClickable && !disabled
							? onClick
							: onClick
					}
					disabled={disabled && !styleDisabled}
					style={customStyle}
					id={id ?? id}
				>
					{Icon}
					<div
						className={"button-text"}
						style={customStyleText}
						dangerouslySetInnerHTML={
							type === "loading" && !isClickable && !disabled
								? { __html: secondaryLabel }
								: { __html: label }
						}
					></div>
				</button>
			)}
		</>
	)
}

Button.propTypes = {
	type: PropTypes.oneOf(["primary", "secondary", "loading", "warning"]),
	size: PropTypes.oneOf(["small", "medium", "large"]),
	textPosition: PropTypes.oneOf(["textLeft", "textCenter", "textRight"]),
	disabled: PropTypes.bool,
	label: PropTypes.string.isRequired,
	onClick: PropTypes.func,
	Icon: PropTypes.element,
	customStyle: PropTypes.object,
	secondaryLabel: PropTypes.string,
	isIconRight: PropTypes.bool,
	isLarge: PropTypes.bool,
	iconRelative: PropTypes.bool,
	styleDisabled: PropTypes.bool, // To use only if you want the button to have disabled style (click is still active)
	linkUrl: PropTypes.string,
	customClass: PropTypes.string,
	customStyleText: PropTypes.object,
	className: PropTypes.string,
	id: PropTypes.string,
	changeVal: PropTypes.bool,
}

Button.defaultProps = {
	disabled: false,
	onClick: undefined,
}
