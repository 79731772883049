import {
	call,
	put,
	takeLeading,
	select,
	take,
	fork,
	cancelled,
	cancel,
} from "redux-saga/effects"

import * as Api from "./services"
import * as gApi from "../../actions/services"

import I18n from "../../i18n"
import { toastr } from "react-redux-toastr"
import { addLCS, removeLCS, arrayToCsv } from "../../actions/generalUtils"
import moment from "moment"

import { createTimeUpdatesChannel } from "./timeUpdateChannel"

export function* changePass(action) {
	try {
		addLCS(action)
		//yield put({ type: "params/setLAR"})
		yield put({
			type: "action/setActionLoader",
			payload: { transparency: true },
		})

		let xLsT = yield select((state) => state.auth.xLsToken)
		let token = yield select((state) => state.auth.token.token)
		let payload = { token, xLsT, data: action.payload }

		const data = yield call(Api.changePass, payload)
		yield put({ type: "action/setActionLoader" })

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return removeLCS(action)
			default: {
				removeLCS(action)

				yield put({ type: "params/toggleLoading" })
				localStorage.setItem(
					"success",
					I18n.t("params.chgPass.success"),
				)

				return yield put({ type: "auth/sessionExpired" })
			}
		}
	} catch (error) {
		//console.log(error)
	}
}

export function* changePin(action) {
	try {
		addLCS(action)
		//yield put({ type: "params/setLAR"})
		yield put({
			type: "action/setActionLoader",
			payload: { transparency: true },
		})

		let xLsT = yield select((state) => state.auth.xLsToken)
		let token = yield select((state) => state.auth.token.token)
		let payload = { token, xLsT, data: action.payload }

		const data = yield call(Api.changePin, payload)
		yield put({ type: "action/setActionLoader" })

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return removeLCS(action)
			default: {
				removeLCS(action)

				yield put({ type: "params/toggleLoading" })
				localStorage.setItem("success", I18n.t("params.chgPin.success"))

				return yield put({ type: "auth/sessionExpired" })
			}
		}
	} catch (error) {
		//console.log(error)
	}
}

export function* checkPin(action) {
	try {
		addLCS(action)

		yield put({
			type: "action/setActionLoader",
			payload: { transparency: true },
		})

		let xLsT = yield select((state) => state.auth.xLsToken)
		let token = yield select((state) => state.auth.token.token)
		let payload = { token, xLsT, data: action.payload }

		const data = yield call(Api.checkPin, payload)
		yield put({ type: "action/setActionLoader" })

		if (data === true) {
			// If the response returns a status 403, "data" is strictly equal to true.
			return yield put({
				type: "params/setLAR",
				payload: { pinPassed: false },
			})
		}

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return removeLCS(action)
			default: {
				removeLCS(action)

				return yield put({
					type: "params/setLAR",
					payload: { pinPassed: true },
				})
			}
		}
	} catch (error) {
		//console.log(error)
	}
}

export function* changeName(action) {
	try {
		addLCS(action)
		yield put({
			type: "action/setActionLoader",
			payload: { transparency: true },
		})

		const xLsT = yield select((state) => state.auth.xLsToken)
		const token = yield select((state) => state.auth.token.token)
		const userId = yield select(
			(state) => state.auth.token.userSession.userId,
		)
		const payload = { token, xLsT, userId, data: action.payload }

		const data = yield call(Api.changeName, payload)
		yield put({ type: "action/setActionLoader" })

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return removeLCS(action)
			default: {
				removeLCS(action)
				toastr.success(I18n.t("params.chgName.success"))

				yield put({ type: "auth/updateUsername", payload: { data } })
				return yield put({ type: "action/closeMiniAction" })
			}
		}
	} catch (error) {
		// console.log("error : ", error)
	}
}

export function* postPP(action) {
	try {
		addLCS(action)
		yield put({ type: "params/setLAR" })
		yield put({
			type: "action/setActionLoader",
			payload: { transparency: true },
		})

		let xLsT = yield select((state) => state.auth.xLsToken)
		let token = yield select((state) => state.auth.token.token)
		let auth = yield select((state) => state.auth)

		let organizationId =
			auth.token.userSession.organizationIds[auth.selectedOrg]

		let payload = {
			token,
			xLsT,
			data: { ...action.payload, organizationId },
		}

		const data = yield call(Api.postPP, payload)
		yield put({ type: "action/setActionLoader" })

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return removeLCS(action)
			default: {
				removeLCS(action)
				//console.log(data)

				toastr.success(I18n.t("params.pp.addSuccess"))

				return yield put({
					type: "params/setLAR",
					payload: { message: "successCreaPP", data },
				})
			}
		}
	} catch (error) {
		//console.log(error)
	}
}

export function* editPP(action) {
	try {
		addLCS(action)
		yield put({ type: "params/setLAR" })
		yield put({
			type: "action/setActionLoader",
			payload: { transparency: true },
		})

		let xLsT = yield select((state) => state.auth.xLsToken)
		let token = yield select((state) => state.auth.token.token)
		let auth = yield select((state) => state.auth)

		let organizationId =
			auth.token.userSession.organizationIds[auth.selectedOrg]

		let payload = {
			token,
			xLsT,
			id: action.payload.id,
			data: { ...action.payload.data, organizationId },
		}

		const data = yield call(Api.editPP, payload)
		yield put({ type: "action/setActionLoader" })

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return removeLCS(action)
			default: {
				removeLCS(action)

				toastr.success(I18n.t("params.pp.editSuccess"))
				return yield put({
					type: "params/setLAR",
					payload: {
						message: action.payload.updateName
							? "sucessEditPPName"
							: "successEditPP",
					},
				})
			}
		}
	} catch (error) {
		//console.log(error)
	}
}

export function* delPP(action) {
	try {
		addLCS(action)
		yield put({ type: "params/setLAR" })
		yield put({
			type: "action/setActionLoader",
			payload: { transparency: true },
		})

		let xLsT = yield select((state) => state.auth.xLsToken)
		let token = yield select((state) => state.auth.token.token)
		let auth = yield select((state) => state.auth)

		let organizationId =
			auth.token.userSession.organizationIds[auth.selectedOrg]

		let payload = { token, xLsT, ...action.payload, organizationId }

		const data = yield call(Api.delPP, payload)
		yield put({ type: "action/setActionLoader" })

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return removeLCS(action)
			default: {
				removeLCS(action)

				toastr.success(I18n.t("params.pp.removeSuccess"))
				yield put({ type: "action/closeMiniAction" })
				return yield put({
					type: "params/setLAR",
					payload: { message: "successDelPP" },
				})
			}
		}
	} catch (error) {
		//console.log(error)
	}
}

// Import passwords
export function* importPass(action) {
	try {
		const sagaPayload = { ...action.payload }

		// Add this saga to the stored history and trigger the loader.
		addLCS(action)
		yield put({ type: "params/setLAR" })
		yield put({
			type: "action/setActionLoader",
			payload: { transparency: true },
		})

		// Get the authentication informations.
		let xLsT = yield select((state) => state.auth.xLsToken)
		let token = yield select((state) => state.auth.token.token)
		let auth = yield select((state) => state.auth)
		let launchPass = yield select((state) => state.params.launchPass)

		let organizationId =
			auth.token.userSession.organizationIds[auth.selectedOrg]

		// Depending on the payload content, we are sending a unique file (the old password importation) or multiple files (the new importation)
		let data = []

		// Old import : simply send the file to the adequate service
		if (sagaPayload.file) {
			let servicePayload = {
				token,
				xLsT,
				data: { ...sagaPayload, organizationId },
			}

			yield put({
				type: "action/startUpload",
				payload: { fileId: sagaPayload.fileId },
			})
			data = yield call(Api.importPass, servicePayload)
			yield put({
				type: "action/endUpload",
				payload: { fileId: sagaPayload.fileId },
			})

			// New import : Start by setting the tracker to get each request speed.
		} else if (sagaPayload.passChunks) {
			// Then, for each chunk of the importation (each chunk is a separate file), launch the timer, send the chunk to the appropriate service and stop the timer.
			for (let i = 0; i < sagaPayload.passChunks.length; i++) {
				let servicePayload = {
					token,
					xLsT,
					data: {
						format: sagaPayload.format,
						file: sagaPayload.passChunks[i],
						organizationId,
						currentChunk: i,
						totalChunkCount: sagaPayload.passChunks.length,
						firstUpload: i === 0 ? "true" : "false",
					},
				}

				yield put({
					type: "action/startUpload",
					payload: { fileId: sagaPayload.fileId },
				})
				const response = yield call(Api.importPass, servicePayload)
				yield put({
					type: "action/endUpload",
					payload: { fileId: sagaPayload.fileId },
				})

				// If the request returned a status "sessionExpired" or "sessionDestroyed", simply exit the loop and assign the data variable to response.
				if (
					response === "sessionExpired" ||
					response === "sessionDestroyed" ||
					response === "badEncode"
				) {
					data = response
					break

					// If response isn’t true or one of the above messages, it means there was an error, push it into the data array.
				} else if (response.length > 0) {
					// Start by modifying the line number of the response, as the number is determined from the chunk file, not the file imported by the user.
					if (i > 0) {
						const rewrittenResponse = response.map((item) => {
							// Get the line that returned an error, and modify it so that it matches the correct line on the imported file, not the chunk.
							const initialLineNb = parseInt(
								item.message.match(/[0-9]{1,4}/)[0],
							)
							const newLineNb =
								initialLineNb + sagaPayload.chunkSize * i
							const newMessage = item.message.replace(
								initialLineNb,
								newLineNb,
							)
							const modifiedLine = { ...item }
							modifiedLine.message = newMessage
							return modifiedLine
						})

						rewrittenResponse.forEach((item) => data.push(item))
					} else {
						// If we are processing the first chunk, no need to modify any number in the response.
						data.push(...response)
					}
				}
			}
		}

		// At this point, if data hasn’t been assigned a value, it means everything went well, give it a "true" value.
		if (!data) data = true

		// Now that the loading is finished, disable the loader.
		yield put({ type: "action/setActionLoader" })
		yield put({ type: "pass/setLoader" })
		yield put({ type: "params/setLoader" })

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case "badEncode":
				return toastr.error(I18n.t("params.iPass.badEncode"))
			case true:
				return removeLCS(action)
			default: {
				removeLCS(action)

				if (data.length === 0) {
					yield put({ type: "params/setLAR" })
					toastr.success(
						I18n.t("params.iPass.importSuccess", {
							s: sagaPayload.totalMdp.length > 1 ? "s" : "",
						}),
					)
					if (launchPass) {
						yield put({
							type: "params/launchPass",
							payload: { launchPass: false },
						})
					}
					yield put({ type: "action/closeAction" })
				} else {
					toastr.warning(I18n.t("params.iPass.importError"))
					toastr.warning(
						`${sagaPayload.totalMdp.length - data.length} ` +
							I18n.t("params.iPass.importNumber", {
								s:
									sagaPayload.totalMdp.length - data.length >
									1
										? "s"
										: "",
							}) +
							` ${sagaPayload.totalMdp.length} ` +
							I18n.t("params.iPass.titleLower", {
								s:
									sagaPayload.totalMdp.length - data.length >
									1
										? "s"
										: "",
							}),
					)
					if (launchPass) {
						yield put({
							type: "params/launchPass",
							payload: { launchPass: false },
						})
					}
					return yield put({
						type: "params/setLAR",
						payload: { message: "errorImportPass", data },
					})
				}
			}
		}
	} catch (error) {
		/* console.log("importPass saga error", error) */
	}
}

export function* importUser(action) {
	try {
		addLCS(action)
		yield put({ type: "params/setLAR" })
		yield put({
			type: "action/setActionLoader",
			payload: { transparency: true },
		})

		let xLsT = yield select((state) => state.auth.xLsToken)
		let token = yield select((state) => state.auth.token.token)
		let auth = yield select((state) => state.auth)

		let organizationId =
			auth.token.userSession.organizationIds[auth.selectedOrg]

		// Comment the next 2 lines if you're trying to implement the remaining
		// time estimation. Their equivalent is in the commented block bellow.
		let payload = {
			token,
			xLsT,
			data: { ...action.payload, organizationId },
		}

		const data = yield call(Api.importUser, payload)

		yield put({ type: "action/setActionLoader" })

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return removeLCS(action)
			default: {
				removeLCS(action)

				if (data.length === 0) {
					yield put({ type: "params/setLAR" })
					toastr.success(I18n.t("params.iUser.importSuccess"))

					return yield put({ type: "action/closeAction" })
				} else {
					toastr.warning(I18n.t("params.iPass.importError"))
					return yield put({
						type: "params/setLAR",
						payload: { message: "errorImportUser", data },
					})
				}
			}
		}
	} catch (error) {
		//console.log(error)
	}
}

export function* importGroupsUsers(action) {
	try {
		addLCS(action)
		yield put({ type: "params/setLAR" })
		yield put({
			type: "action/setActionLoader",
			payload: { transparency: true },
		})

		let xLsT = yield select((state) => state.auth.xLsToken)
		let token = yield select((state) => state.auth.token.token)
		let auth = yield select((state) => state.auth)

		let organizationId =
			auth.token.userSession.organizationIds[auth.selectedOrg]

		let payload = {
			token,
			xLsT,
			data: { ...action.payload, organizationId },
		}

		const data = yield call(Api.importGroupsUsers, payload)
		yield put({ type: "action/setActionLoader" })

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return removeLCS(action)
			default: {
				removeLCS(action)

				if (data.length === 0) {
					yield put({ type: "params/setLAR" })
					toastr.success(I18n.t("params.iGroups.importSuccess"))

					return yield put({ type: "action/closeAction" })
				} else {
					toastr.warning(I18n.t("params.iPass.importError"))
					return yield put({
						type: "params/setLAR",
						payload: { message: "errorImportGroupsUser", data },
					})
				}
			}
		}
	} catch (error) {
		//console.log(error)
	}
}

export function* importArborescence(action) {
	try {
		addLCS(action)
		yield put({ type: "params/setLAR" })
		yield put({
			type: "action/setActionLoader",
			payload: { transparency: true },
		})

		let xLsT = yield select((state) => state.auth.xLsToken)
		let token = yield select((state) => state.auth.token.token)
		let auth = yield select((state) => state.auth)

		let organizationId =
			auth.token.userSession.organizationIds[auth.selectedOrg]

		// If you want to implement the remaining time estimation, remove the
		// next 2 lines (WARNING : Only do so if the backend has fixed the bug !)
		let payload = {
			token,
			xLsT,
			data: { ...action.payload, organizationId },
		}

		const data = yield call(Api.importArborescence, payload)

		yield put({ type: "pass/setLoader" })
		yield put({ type: "params/setLoader" })
		yield put({ type: "action/setActionLoader" })

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return removeLCS(action)
			default: {
				removeLCS(action)

				if (data.length === 0) {
					yield put({ type: "params/setLAR" })
					toastr.success(I18n.t("params.iArborescence.importSuccess"))

					return yield put({ type: "action/closeAction" })
				} else {
					toastr.warning(I18n.t("params.iPass.importError"))
					return yield put({
						type: "params/setLAR",
						payload: { message: "errorImportUser", data },
					})
				}
			}
		}
	} catch (error) {
		//console.log(error)
	}
}

export function* importOrg(action) {
	try {
		addLCS(action)
		yield put({ type: "params/setLAR" })
		yield put({
			type: "action/setActionLoader",
			payload: { transparency: true },
		})

		let xLsT = yield select((state) => state.auth.xLsToken)
		let token = yield select((state) => state.auth.token.token)
		let auth = yield select((state) => state.auth)

		let organizationId =
			auth.token.userSession.organizationIds[auth.selectedOrg]

		let payload = {
			token,
			xLsT,
			data: { ...action.payload, organizationId },
		}

		const data = yield call(Api.importOrg, payload)
		yield put({ type: "action/setActionLoader" })

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return removeLCS(action)
			default: {
				removeLCS(action)

				if (data.length === 0) {
					yield put({ type: "params/setLAR" })
					toastr.success(I18n.t("params.iOrg.importSuccess"))

					return yield put({ type: "action/closeAction" })
				} else {
					toastr.warning(I18n.t("params.iOrg.importError"))
					return yield put({
						type: "params/setLAR",
						payload: { message: "errorImportOrg", data },
					})
				}

				//return yield put({ type: "params/setLAR", payload: {message: "successDelPP"} })
			}
		}
	} catch (error) {
		//console.log(error)
	}
}

export function* importCat(action) {
	try {
		const sagaPayload = { ...action.payload }
		const actionLoader = yield select((state) => state.action.loader)

		addLCS(action)
		yield put({ type: "params/setLAR" })

		if (!actionLoader) {
			yield put({
				type: "action/setActionLoader",
				payload: { transparency: true },
			})
		}

		let xLsT = yield select((state) => state.auth.xLsToken)
		let token = yield select((state) => state.auth.token.token)
		let auth = yield select((state) => state.auth)
		let organizationId =
			auth.token.userSession.organizationIds[auth.selectedOrg]
		let data = []

		if (sagaPayload.passChunks) {
			for (let i = 0; i < sagaPayload.passChunks.length; i++) {
				let servicePayload = {
					token,
					xLsT,
					data: {
						file: sagaPayload.passChunks[i],
						organizationId,
						currentChunk: i,
						totalChunkCount: sagaPayload.passChunks.length,
						firstUpload: i === 0 ? "true" : "false",
					},
				}

				yield put({
					type: "action/startUpload",
					payload: { fileId: sagaPayload.fileId },
				})

				const response = yield call(Api.importCat, servicePayload)

				yield put({
					type: "action/endUpload",
					payload: { fileId: sagaPayload.fileId },
				})

				if (
					response === "sessionExpired" ||
					response === "sessionDestroyed"
				) {
					data = response
					break
				} else if (response.length > 0) {
					if (i > 0) {
						const rewrittenResponse = response.map((item) => {
							const initialLineNb = parseInt(
								item.message.match(/[0-9]{1,4}/)[0],
							)
							const newLineNb =
								initialLineNb + sagaPayload.chunkSize * i
							const newMessage = item.message.replace(
								initialLineNb,
								newLineNb,
							)
							const modifiedLine = { ...item }
							modifiedLine.message = newMessage
							return modifiedLine
						})
						rewrittenResponse.forEach((item) => data.push(item))
					} else {
						data.push(...response)
					}
				}
			}
		}

		if (!data) {
			data = true
		}

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return removeLCS(action)
			default: {
				removeLCS(action)

				if (data.length === 0) {
					yield put({ type: "params/setLAR" })
					toastr.success(I18n.t("params.iCat.importSuccess"))
					if (sagaPayload.close) {
						yield put({ type: "action/closeAction" })
					}
					if (sagaPayload.keepass) {
						yield put({
							type: "params/launchPass",
							payload: { launchPass: true },
						})
						yield put({
							type: "pass/init",
							payload: { extensiveReset: true },
						})
					}
				} else {
					toastr.warning(I18n.t("params.iCat.importError"))
					yield put({ type: "action/setActionLoader" })
					return yield put({
						type: "params/setLAR",
						payload: { message: "errorImportCat", data },
					})
				}

				yield put({ type: "action/setActionLoader" })
			}
		}
	} catch (error) {}
}

export function* exportPass(action) {
	try {
		addLCS(action)
		yield put({ type: "params/setLAR" })
		yield put({
			type: "action/setActionLoader",
			payload: { transparency: true },
		})

		let xLsT = yield select((state) => state.auth.xLsToken)
		let token = yield select((state) => state.auth.token.token)

		let payload = { token, xLsT, data: action.payload }
		const isPinCorrect = yield call(gApi.checkPin, payload)

		if (!isPinCorrect) {
			yield put({ type: "action/setActionLoader" })
			return toastr.error(I18n.t("params.exPass.badPin"))
		}

		const data = yield call(Api.exportPass, payload)
		yield put({ type: "action/setActionLoader" })
		yield put({ type: "action/closeAction" })

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return removeLCS(action)
			default: {
				removeLCS(action)

				let rows = []
				data.map((d) => {
					let row = []
					d.map((a) => {
						row.push(`"${a}"`)
					})
					rows.push(row)
				})

				const csvContent = rows.map((e) => e.join(";")).join("\n")
				//var blob = new Blob([csvContent], { type: "text/csv;charset=windows-1252",encoding: "windows-1252" });
				const blob = new Blob([csvContent], {
					type: "text/csv;charset=utf-8;",
				})
				const url = URL.createObjectURL(blob)
				//var url = "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURI(csvContent)

				const link = document.createElement("a")
				link.setAttribute("href", url)
				link.setAttribute(
					"download",
					`lockselfCredentialsExport-${moment().format(
						"HH-mm-ss",
					)}.csv`,
				)
				document.body.appendChild(link)

				link.click()
				return yield put({
					type: "params/setLAR",
					payload: { message: "successDelPP" },
				})
			}
		}
	} catch (error) {
		//console.log(error)
	}
}

// This is a generator function that watches for time updates.
export function* watchTimeUpdates() {
	// A channel is created to receive time updates.
	const channel = yield call(createTimeUpdatesChannel)
	// The active variable is used to keep track of whether the process is active.
	const active = true
	try {
		// An infinite loop is started to continuously watch for time updates.
		while (true) {
			// The next time update is taken from the channel.
			const remainingTime = yield take(channel)
			// An action is dispatched to update the state with the new remaining time.
			yield put({
				type: "params/setExportLoader",
				payload: { active, remainingTime, percent: 23 },
			})
		}
	} finally {
		// If the saga is cancelled, the channel is closed.
		if (yield cancelled()) {
			channel.close()
		}
	}
}

// This is a generator function that handles the export of data in an optimized way.
export function* exportPassPersoOpti(action) {
	try {
		// The action is added to the Local Client Storage (LCS).
		addLCS(action)
		// An action is dispatched to set the Loading And Remaining (LAR) state.
		yield put({ type: "params/setLAR" })

		yield put({ type: "params/toggleLoading", payload: true })

		// The xLsToken and token are selected from the state.
		let xLsT = yield select((state) => state.auth.xLsToken)
		let token = yield select((state) => state.auth.token.token)

		// The payload for the API call is constructed.
		let payload = { token, xLsT, data: action.payload }
		const isPinCorrect = yield call(gApi.checkPin, payload)

		if (!isPinCorrect) {
			yield put({ type: "params/toggleLoading" })
			return toastr.error(I18n.t("params.exPass.badPin"))
		} else {
			yield put({ type: "action/closeAction" })
		}

		// An action is dispatched to set the export loader state to active and the remaining time to an empty string.
		yield put({
			type: "action/setActionLoader",
			payload: { transparency: true },
		})

		// The watchTimeUpdates saga is forked to run concurrently.
		const timeUpdateTask = yield fork(watchTimeUpdates)

		// The API call is made.
		const data = yield call(Api.exportPassPersoOpti, payload)

		// An action is dispatched to toggle the loading state.
		yield put({ type: "action/setActionLoader" })
		yield put({ type: "params/toggleLoading", payload: false })

		// The response from the API call is handled.
		switch (data) {
			case "sessionExpired":
				// If the session has expired, an action is dispatched to show the padlock.
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				// If the session has been destroyed, an action is dispatched to handle the session expiration.
				return yield put({ type: "auth/sessionExpired" })
			case true:
				// If the API call was successful, the action is removed from the LCS.
				return removeLCS(action)
			default: {
				// If the API call returned data, the time update task is cancelled.
				yield cancel(timeUpdateTask)
				// If no data in the personal space, throw error on toster
				if (data?.error) {
					return toastr.error(I18n.t("params.iPass.noPwdPerso"))
				}
				// The data is converted to CSV format.
				const csvContent = arrayToCsv(data)
				// A blob is created from the CSV content.
				const blob = new Blob([csvContent], {
					type: "text/csv;charset=utf-8;",
				})
				// A URL is created for the blob.
				const url = URL.createObjectURL(blob)
				// A link is created and set to download the blob as a CSV file.
				const link = document.createElement("a")
				link.href = url
				link.setAttribute("download", "export.csv")
				// The link is added to the document and clicked to start the download.
				document.body.appendChild(link)
				link.click()
				// The link is removed from the document.
				document.body.removeChild(link)
			}
		}
	} catch (error) {
		// Any errors are logged.
		// console.log("error in exportPassPersoOpti", error)
	}
}

export function* exportAllPass(action) {
	try {
		addLCS(action)
		yield put({ type: "params/setLAR" })
		yield put({
			type: "action/setActionLoader",
			payload: { transparency: true },
		})

		let xLsT = yield select((state) => state.auth.xLsToken)
		let token = yield select((state) => state.auth.token.token)

		//Check pin validity
		let payload = { token, xLsT, data: action.payload }

		const isPinCorrect = yield call(gApi.checkPin, payload)

		if (!isPinCorrect) {
			toastr.error(I18n.t("params.exPass.badPin"))
			return yield put({ type: "action/setActionLoader" })
			// return yield put({ type: "action/closeAction" })
			//return yield put({ type: "params/setLAR", payload: { message: "badBin" } })
		}

		// Export then
		let auth = yield select((state) => state.auth)
		let organizationId =
			auth.token.userSession.organizationIds[auth.selectedOrg]

		payload = { token, xLsT, organizationId }

		const data = yield call(Api.exportAllPass, payload)
		yield put({ type: "action/setActionLoader" })

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return removeLCS(action)
			default: {
				removeLCS(action)

				let a = document.createElement("a")
				a.href = window.URL.createObjectURL(data)
				a.download = `${moment().format(
					"YYYYMMDD-HHmmSS",
				)}-credential-export.csv.enc`
				document.body.appendChild(a)
				a.click()
				a.remove()

				toastr.success(I18n.t("params.exPass.success"))
				return yield put({
					type: "params/setLAR",
					payload: { message: "allPassExported" },
				})
				//return yield put({ type: "action/closeAction" })
			}
		}
	} catch (error) {
		//console.log(error)
	}
}

export function* exportPassAndFiles(action) {
	try {
		addLCS(action)
		yield put({ type: "params/setLAR" })
		yield put({
			type: "action/setActionLoader",
			payload: { transparency: true },
		})

		let auth = yield select((state) => state.auth)
		let xLsT = yield select((state) => state.auth.xLsToken)
		let token = yield select((state) => state.auth.token.token)

		let organizationId =
			auth.token.userSession.organizationIds[auth.selectedOrg]
		let payload = { token, xLsT, organizationId, data: action.payload }

		const isPinCorrect = yield call(gApi.checkPin, payload)

		if (!isPinCorrect) {
			toastr.error(I18n.t("params.exPass.badPin"))
			return yield put({ type: "action/setActionLoader" })
		}

		const data = yield call(Api.exportPassAndFiles, payload)
		yield put({ type: "action/setActionLoader" })

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return removeLCS(action)
			default: {
				removeLCS(action)

				toastr.success(I18n.t("params.exPass.inProgress"))
				return yield put({
					type: "action/closeAction",
					payload: "exportPassFiles",
				})
			}
		}
	} catch (error) {
		//console.log(error)
	}
}

export function* exportUsersOrga(action) {
	try {
		addLCS(action)
		yield put({ type: "params/setLAR" })
		yield put({
			type: "action/setActionLoader",
			payload: { transparency: true },
		})

		const xLsT = yield select((state) => state.auth.xLsToken)
		const token = yield select((state) => state.auth.token.token)

		const payload = { token, xLsT, id: action.payload.id }

		const data = yield call(Api.exportUsersOrga, payload)
		yield put({ type: "action/setActionLoader" })

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return removeLCS(action)
			default: {
				removeLCS(action)

				let documentLink = document.createElement("a")
				documentLink.href = window.URL.createObjectURL(data)
				documentLink.download =
					("download",
					`lockselfUsersExport-${moment().format(
						"YYYYMMDD-HHmmSS",
					)}.csv`)
				document.body.appendChild(documentLink)
				documentLink.click()
				documentLink.remove()

				toastr.success(I18n.t("params.exUser.success"))
				return
			}
		}
	} catch (error) {
		// console.log(error)
	}
}

export function* getUserKey() {
	try {
		yield put({
			type: "action/setActionLoader",
			payload: { transparency: true },
		})

		let xLsT = yield select((state) => state.auth.xLsToken)
		let token = yield select((state) => state.auth.token.token)

		let payload = { token, xLsT }
		const data = yield call(Api.getUserKey, payload)

		yield put({ type: "action/setActionLoader" })

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return
			default: {
				//console.log(data)

				if (data === "noKey") {
					yield put({
						type: "params/setLAR",
						payload: { message: "noKey" },
					})
				} else {
					yield put({
						type: "params/setLAR",
						payload: { message: "gotKey", data },
					})
				}

				return
			}
		}
	} catch (error) {
		//console.log(error)
	}
}

export function* addUserKey(action) {
	try {
		yield put({
			type: "action/setActionLoader",
			payload: { transparency: true },
		})
		let xLsT = yield select((state) => state.auth.xLsToken)
		let token = yield select((state) => state.auth.token.token)

		let payload = { token, xLsT, data: { ...action.payload } }
		const data = yield call(Api.addUserKey, payload)

		yield put({ type: "action/setActionLoader" })

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return
			default: {
				toastr.success(I18n.t("params.exPass.keyAdded"))
				return yield put({
					type: "params/setLAR",
					payload: { message: "keyAdded", data },
				})
			}
		}
	} catch (error) {
		//console.log(error)
	}
}

export function* delUserKey(action) {
	try {
		yield put({
			type: "action/setActionLoader",
			payload: { transparency: true },
		})
		let xLsT = yield select((state) => state.auth.xLsToken)
		let token = yield select((state) => state.auth.token.token)

		let payload = { token, xLsT, id: action.payload.id }
		const data = yield call(Api.delUserKey, payload)

		yield put({ type: "action/setActionLoader" })

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return
			default: {
				toastr.success(I18n.t("params.exPass.keyDeleted"))
				return yield put({
					type: "params/setLAR",
					payload: { message: "keyDeleted" },
				})
			}
		}
	} catch (error) {
		//console.log(error)
	}
}

// export function* getUser(action) {
// 	try {
// 		let xLsT = yield select(state => state.auth.xLsToken)
// 		let token = yield select(state => state.auth.token.token)

// 		let payload = { token, xLsT, id: action.payload }

// 		const data = yield call(ApiParams.getUser, payload)

// 		switch (data) {
// 			case ("sessionExpired") : return yield put({ type: "auth/showPadlock" })
// 			case ("sessionDestroyed") : return yield put({ type: "auth/sessionExpired" })
// 			case (true) : return
// 			default : {

// 				return yield  put({  type: "params/setIsModerator", payload })
// 			}
// 		}

// 	} catch (error) {
// 		//console.log(error)
// 	}
// }

export function* getSMTP() {
	try {
		yield put({
			type: "action/setActionLoader",
			payload: { transparency: true },
		})

		let xLsT = yield select((state) => state.auth.xLsToken)
		let token = yield select((state) => state.auth.token.token)
		let wmId = yield select(
			(state) => state.auth.token.userSession.userWhitemark.whitemark_id,
		)

		let payload = { token, xLsT, wmId }
		const data = yield call(Api.getSMTP, payload)

		yield put({ type: "action/setActionLoader" })

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return
			default: {
				return yield put({
					type: "params/setLAR",
					payload: { message: "getSMTP", data },
				})
			}
		}
	} catch (error) {
		//console.log(error)
	}
}

export function* saveSMTP(action) {
	try {
		addLCS(action)
		yield put({ type: "params/setLAR" })
		yield put({
			type: "action/setActionLoader",
			payload: { transparency: true },
		})

		let xLsT = yield select((state) => state.auth.xLsToken)
		let token = yield select((state) => state.auth.token.token)
		let payload = { token, xLsT, ...action.payload }

		const data = yield call(Api.saveSMTP, payload)
		yield put({ type: "action/setActionLoader" })

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return removeLCS(action)
			default: {
				removeLCS(action)
				//toastr.success(I18n.t("params.iSMTP.successPost"))
				return yield put({
					type: "params/setLAR",
					payload: { message: "postSMTP" },
				})
			}
		}
	} catch (error) {
		//console.log(error)
	}
}

export function* changeSMTP(action) {
	try {
		addLCS(action)
		yield put({ type: "params/setLAR" })
		yield put({
			type: "action/setActionLoader",
			payload: { transparency: true },
		})

		let xLsT = yield select((state) => state.auth.xLsToken)
		let token = yield select((state) => state.auth.token.token)
		let payload = { token, xLsT, ...action.payload }

		const data = yield call(Api.changeSMTP, payload)
		yield put({ type: "action/setActionLoader" })

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return removeLCS(action)
			default: {
				removeLCS(action)
				//toastr.success(I18n.t("params.iSMTP.successPost"))
				return yield put({
					type: "params/setLAR",
					payload: { message: "putSMTP" },
				})
			}
		}
	} catch (error) {
		//console.log(error)
	}
}

export function* testSMTP(action) {
	try {
		addLCS(action)
		yield put({ type: "params/setLAR" })
		yield put({
			type: "action/setActionLoader",
			payload: { transparency: true },
		})

		let xLsT = yield select((state) => state.auth.xLsToken)
		let token = yield select((state) => state.auth.token.token)

		let payload = { token, xLsT, ...action.payload } //, data: {...action.payload,organizationId} }

		const data = yield call(Api.testSMTP, payload)
		yield put({ type: "action/setActionLoader" })

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return removeLCS(action)
			default: {
				if (data?.code === 422) {
					return yield put({
						type: "params/setLAR",
						payload: { message: "failTest", data },
					})
				} else {
					toastr.success(I18n.t("params.iSMTP.test.success"))
					yield put({
						type: "params/setLAR",
						payload: { message: "emailSend" },
					})
					return yield put({ type: "action/closeMiniAction" })
				}
			}
		}
	} catch (error) {
		//console.log(error)
	}
}

export function* csvError(action) {
	try {
		yield put({ type: "actions/setLAR" })
		addLCS(action)

		const xLsT = yield select((state) => state.auth.xLsToken)
		const token = yield select((state) => state.auth.token.token)
		const payload = { token, xLsT, ...action.payload }

		const data = yield call(Api.csvError, payload)

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return removeLCS(action)
			default: {
				const url = URL.createObjectURL(data)
				const link = document.createElement("a")
				link.setAttribute("href", url)
				link.setAttribute(
					"download",
					`lockselfErrorImportPassword-${moment().format(
						"HH-mm-ss",
					)}.csv`,
				)
				document.body.appendChild(link)

				link.click()
			}
		}
	} catch (error) {
		//console.log(error)
	}
}

export function* getAdminData(action) {
	try {
		const xLsT = yield select((state) => state.auth.xLsToken)
		const token = yield select((state) => state.auth.token.token)
		const uuid = yield select(
			(state) => state.params.optAdministration.uuid,
		)

		let payload = { token, xLsT, uuid, ...action.payload }
		const data = yield call(Api.getAdministration, payload)

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return removeLCS(action)
			default: {
				let json = localStorage.getItem("options")
				let option = JSON.parse(json)
				// let decrypt = decryptOption(option)

				option.administrationOptions = data

				localStorage.setItem("options", JSON.stringify(option))

				return yield put({ type: "params/setAdmin", payload: data })
			}
		}
	} catch (error) {
		//console.log(error)
	}
}

export function* updateAdminData(action) {
	try {
		let xLsT = yield select((state) => state.auth.xLsToken)
		let token = yield select((state) => state.auth.token.token)
		let uuid = yield select((state) => state.params.optAdministration.uuid)
		if (action.payload.whitelistIPs) delete action.payload.whitelistIPs

		let payload = {
			token,
			xLsT,
			uuid,
			data: action.payload,
		}

		const data = yield call(Api.updateAdministration, payload)

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return removeLCS(action)
			default: {
				let json = localStorage.getItem("options")
				let option = JSON.parse(json)
				// let decrypt = decryptOption(option)

				option.administrationOptions = data
				localStorage.setItem("options", JSON.stringify(option))

				return yield put({ type: "params/setAdmin", payload: data })
			}
		}
	} catch (error) {
		//console.log(error)
	}
}

export function* addWhitelistIP(action) {
	try {
		let xLsT = yield select((state) => state.auth.xLsToken)
		let token = yield select((state) => state.auth.token.token)
		let uuid = yield select((state) => state.params.optAdministration.uuid)

		let payload = {
			token,
			xLsT,
			uuid,
			data: action.payload,
		}

		const data = yield call(Api.addWhitelistIP, payload)

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return removeLCS(action)
			default: {
				let json = localStorage.getItem("options")
				let option = JSON.parse(json)
				// let decrypt = decryptOption(option)

				option.administrationOptions.whitelistIPs.push(data)
				localStorage.setItem("options", JSON.stringify(option))

				return yield put({
					type: "params/setWhitelistIp",
					payload: option.administrationOptions.whitelistIPs,
				})
			}
		}
	} catch (error) {
		//console.log(error)
	}
}

export function* deleteIp(action) {
	try {
		let xLsT = yield select((state) => state.auth.xLsToken)
		let token = yield select((state) => state.auth.token.token)
		let uuid = yield select((state) => state.params.optAdministration.uuid)

		// Comment the next 2 lines if you're trying to implement the remaining
		// time estimation. Their equivalent is in the commented block bellow.
		let payload = {
			token,
			xLsT,
			uuid,
			ipUuid: action.payload.ipUuid,
		}

		const data = yield call(Api.deleteIp, payload)

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return removeLCS(action)
			default: {
				let json = localStorage.getItem("options")
				let option = JSON.parse(json)
				// let decrypt = decryptOption(option)

				let index = option.administrationOptions.whitelistIPs.findIndex(
					(ip) => ip.uuid === action.payload.ipUuid,
				)
				if (index !== -1)
					option.administrationOptions.whitelistIPs.splice(index, 1)

				localStorage.setItem("options", JSON.stringify(option))
				return yield put({
					type: "params/setWhitelistIp",
					payload: option.administrationOptions.whitelistIPs,
				})
			}
		}
	} catch (error) {}
}

export function* getLockpassOpt(action) {
	try {
		const xLsT = yield select((state) => state.auth.xLsToken)
		const token = yield select((state) => state.auth.token.token)
		const uuid = yield select((state) => state.params.optLockPass?.uuid)

		let payload = { token, xLsT, uuid, ...action.payload }
		const data = yield call(Api.getLockpassOpt, payload)

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return removeLCS(action)
			default: {
				let json = localStorage.getItem("options")
				let option = JSON.parse(json)
				// let decrypt = decryptOption(option)
				option.lockpassOptions = data

				localStorage.setItem("options", JSON.stringify(option))

				return yield put({ type: "params/setLP", payload: data })
			}
		}
	} catch (error) {
		//console.log(error)
	}
}

export function* updateLockpassOtp(action) {
	try {
		let xLsT = yield select((state) => state.auth.xLsToken)
		let token = yield select((state) => state.auth.token.token)
		let uuid = yield select((state) => state.params.optLockPass.uuid)
		if (action.payload.whitelistIPs) delete action.payload.whitelistIPs

		let payload = {
			token,
			xLsT,
			uuid,
			data: action.payload,
		}

		const data = yield call(Api.updateLockPass, payload)

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return removeLCS(action)
			default: {
				let json = localStorage.getItem("options")
				let option = JSON.parse(json)
				// let decrypt = decryptOption(option)

				option.lockpassOptions = data
				localStorage.setItem("options", JSON.stringify(option))

				return yield put({ type: "params/setLP", payload: data })
			}
		}
	} catch (error) {
		//console.log(error)
	}
}

export function* updatePasswordPolicy(action) {
	try {
		const xLsT = yield select((state) => state.auth.xLsToken)
		const token = yield select((state) => state.auth.token.token)
		const auth = yield select((state) => state.auth)

		const organizationId =
			auth.token.userSession.organizationIds[auth.selectedOrg]

		if (action.payload.whitelistIPs) delete action.payload.whitelistIPs

		const payload = {
			token,
			xLsT,
			organizationId,
			policyId: action.payload.policyId,
			data: action.payload.data,
		}

		const data = yield call(Api.updatePasswordPolicy, payload)

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return removeLCS(action)
			default: {
				let json = localStorage.getItem("options")
				let option = JSON.parse(json)
				// let decrypt = decryptOption(option)

				option.lockpassOptions.passwordPolicyId =
					action.payload.policyId

				// option.lockpassOptions = { ...option.lockpassOptions }
				localStorage.setItem("options", JSON.stringify(option))

				return yield put({
					type: "params/setLP",
					payload: option.lockpassOptions,
				})
			}
		}
	} catch (error) {
		//console.log(error)
	}
}

export function* getLocktranferOpt(action) {
	try {
		const xLsT = yield select((state) => state.auth.xLsToken)
		const token = yield select((state) => state.auth.token.token)
		const uuid = yield select((state) => state.params.optLockTransfer.uuid)

		let payload = { token, xLsT, uuid, ...action.payload }
		const data = yield call(Api.getLocktransferOpt, payload)

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return removeLCS(action)
			default: {
				let json = localStorage.getItem("options")
				let option = JSON.parse(json)
				// let decrypt = decryptOption(option)
				option.locktransferOptions = data

				localStorage.setItem("options", JSON.stringify(option))

				return yield put({ type: "params/setLT", payload: data })
			}
		}
	} catch (error) {
		//console.log(error)
	}
}

export function* updateLockTransfer(action) {
	try {
		const xLsT = yield select((state) => state.auth.xLsToken)
		const token = yield select((state) => state.auth.token.token)
		const uuid = yield select((state) => state.params.optLockTransfer.uuid)

		let payload = { token, xLsT, uuid, ...action.payload }
		const data = yield call(Api.updateLocktransfer, payload)

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return removeLCS(action)
			default: {
				let json = localStorage.getItem("options")
				let option = JSON.parse(json)
				// let decrypt = decryptOption(option)
				option.locktransferOptions = data

				localStorage.setItem("options", JSON.stringify(option))

				return yield put({ type: "params/setLT", payload: data })
			}
		}
	} catch (error) {
		//console.log(error)
	}
}

export function* getLockFilesOpt(action) {
	try {
		const xLsT = yield select((state) => state.auth.xLsToken)
		const token = yield select((state) => state.auth.token.token)
		const uuid = yield select((state) => state.params.optLockFiles.uuid)

		let payload = { token, xLsT, uuid, ...action.payload }
		const data = yield call(Api.getLockfilesOtp, payload)

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return removeLCS(action)
			default: {
				let json = localStorage.getItem("options")
				let option = JSON.parse(json)
				// let decrypt = decryptOption(option)
				option.lockfilesOptions = data

				localStorage.setItem("options", JSON.stringify(option))

				return yield put({ type: "params/setLF", payload: data })
			}
		}
	} catch (error) {
		//console.log(error)
	}
}

export function* updateLockFiles(action) {
	try {
		const xLsT = yield select((state) => state.auth.xLsToken)
		const token = yield select((state) => state.auth.token.token)
		const uuid = yield select((state) => state.params.optLockFiles.uuid)

		let payload = { token, xLsT, uuid, ...action.payload }
		const data = yield call(Api.updateLockfiles, payload)

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return removeLCS(action)
			default: {
				let json = localStorage.getItem("options")
				let option = JSON.parse(json)
				// let decrypt = decryptOption(option)
				option.lockfilesOptions = data

				localStorage.setItem("options", JSON.stringify(option))

				return yield put({ type: "params/setLF", payload: data })
			}
		}
	} catch (error) {
		//console.log(error)
	}
}

export function* getManagementOpt(action) {
	try {
		const xLsT = yield select((state) => state.auth.xLsToken)
		const token = yield select((state) => state.auth.token.token)
		const uuid = yield select((state) => state.params.optManagement.uuid)

		let payload = { token, xLsT, uuid, ...action.payload }
		const data = yield call(Api.getManagementsOtp, payload)

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return removeLCS(action)
			default: {
				let json = localStorage.getItem("options")
				let option = JSON.parse(json)
				// let decrypt = decryptOption(option)
				option.managementOptions = data

				localStorage.setItem("options", JSON.stringify(option))

				return yield put({
					type: "params/setManagement",
					payload: data,
				})
			}
		}
	} catch (error) {
		//console.log(error)
	}
}

export function* updateManagement(action) {
	try {
		const xLsT = yield select((state) => state.auth.xLsToken)
		const token = yield select((state) => state.auth.token.token)
		const uuid = yield select((state) => state.params.optManagement.uuid)
		if (action.payload.data.globalWhitelistDomains)
			delete action.payload.data.globalWhitelistDomains
		if (action.payload.data.organizationWhitelistDomains)
			delete action.payload.data.organizationWhitelistDomains

		let payload = { token, xLsT, uuid, ...action.payload }

		const data = yield call(Api.updateManagement, payload)

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return removeLCS(action)
			default: {
				let json = localStorage.getItem("options")
				let option = JSON.parse(json)
				// let decrypt = decryptOption(option)
				option.managementOptions = data

				localStorage.setItem("options", JSON.stringify(option))

				return yield put({
					type: "params/setManagement",
					payload: data,
				})
			}
		}
	} catch (error) {
		//console.log(error)
	}
}

export function* addDomaine(action) {
	try {
		const xLsT = yield select((state) => state.auth.xLsToken)
		const token = yield select((state) => state.auth.token.token)
		const uuid = yield select((state) => state.params.optManagement.uuid)

		let payload = { token, xLsT, uuid, ...action.payload }
		const data = yield call(Api.addDomaine, payload)

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return removeLCS(action)
			default: {
				let json = localStorage.getItem("options")
				let option = JSON.parse(json)
				// let decrypt = decryptOption(option)
				if (data.isAllOrganizations) {
					option.managementOptions.globalWhitelistDomains.push(data)
				} else {
					option.managementOptions.organizationWhitelistDomains.push(
						data,
					)
				}

				localStorage.setItem("options", JSON.stringify(option))

				return yield put({
					type: "params/setWhitelistDomaine",
					payload: data,
				})
			}
		}
	} catch (error) {
		//console.log(error)
	}
}

export function* deleteDomaine(action) {
	try {
		const xLsT = yield select((state) => state.auth.xLsToken)
		const token = yield select((state) => state.auth.token.token)
		const uuid = yield select((state) => state.params.optManagement.uuid)

		let payload = { token, xLsT, uuid, ...action.payload }
		const data = yield call(Api.deleteDomaine, payload)

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return removeLCS(action)
			default: {
				let json = localStorage.getItem("options")
				let option = JSON.parse(json)

				let indexGlobal =
					option.managementOptions.globalWhitelistDomains.findIndex(
						(ip) => ip.uuid === action.payload.domaineUuid,
					)

				const indexOrga =
					option.managementOptions.organizationWhitelistDomains.findIndex(
						(ip) => ip.uuid === action.payload.domaineUuid,
					)

				if (indexGlobal !== -1) {
					option.managementOptions.globalWhitelistDomains.splice(
						indexGlobal,
						1,
					)
				} else if (indexOrga !== -1) {
					option.managementOptions.organizationWhitelistDomains.splice(
						indexOrga,
						1,
					)
				}

				localStorage.setItem("options", JSON.stringify(option))

				return yield put({
					type: "params/setWhitelist",
					payload:
						indexGlobal !== -1
							? option.managementOptions.globalWhitelistDomains
							: option.managementOptions
									.organizationWhitelistDomains,
					list: indexGlobal !== -1,
				})
			}
		}
	} catch (error) {
		//console.log(error)
	}
}

export function* getMFASetup(action) {
	try {
		yield put({ type: "params/setLAR" })
		yield put({
			type: "action/setActionLoader",
			payload: {
				transparency: true,
			},
		})
		addLCS(action)

		const xLsT = yield select((state) => state.auth.xLsToken)
		const token = yield select((state) => state.auth.token.token)
		const payload = { token, xLsT }

		const data = yield call(Api.setupMFA, payload)

		switch (data) {
			case "sessionExpired":
				yield put({ type: "action/setActionLoader" })
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				yield put({ type: "action/setActionLoader" })
				return yield put({ type: "auth/sessionExpired" })
			case true:
				yield put({ type: "action/setActionLoader" })
				return removeLCS(action)
			default: {
				removeLCS(action)
				if (data === "mustRemoveSecretKey") {
					return yield put({
						type: "OPTIONS_MFA_CANCEL_SETUP",
						payload: { setupLoop: true },
					})
				}

				yield put({ type: "action/setActionLoader" })
				yield put({
					type: "params/setLAR",
					payload: {
						message: "successSetMFA",
						data,
					},
				})
			}
		}
	} catch (error) {
		yield put({ type: "action/setActionLoader" })
		console.warn(error)
	}
}

export function* confirmMFA(action) {
	try {
		yield put({ type: "params/setLAR" })
		yield put({
			type: "action/setActionLoader",
			payload: {
				transparency: true,
			},
		})
		addLCS(action)

		const xLsT = yield select((state) => state.auth.xLsToken)
		const token = yield select((state) => state.auth.token.token)
		const servicePayload = { token, xLsT, mfaCode: action.payload.mfaCode }

		const data = yield call(Api.confirmMFA, servicePayload)
		yield put({ type: "action/setActionLoader" })

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return removeLCS(action)
			default: {
				removeLCS(action)
				if (data.operationSucceeded) {
					toastr.success(
						I18n.t("params.MFA.create.check.toastSuccess"),
					)
					yield put({
						type: "params/setLAR",
						payload: {
							message: "successConfirmMFA",
							data,
						},
					})
				} else {
					toastr.error(I18n.t("params.MFA.create.check.toastFailure"))
					yield put({
						type: "params/setLAR",
						payload: {
							message: "failureConfirmMFA",
						},
					})
				}
			}
		}
	} catch (error) {
		console.warn(error)
	}
}

export function* getMFARecovery(action) {
	try {
		yield put({ type: "params/setLAR" })
		yield put({
			type: "action/setActionLoader",
			payload: {
				transparency: true,
			},
		})
		addLCS({ type: "OPTIONS_MFA_RECOVERY" })

		const xLsT = yield select((state) => state.auth.xLsToken)
		const token = yield select((state) => state.auth.token.token)
		const payload = { token, xLsT }

		const data = yield call(Api.getMFARecoveryCodes, payload)
		yield put({ type: "action/setActionLoader" })

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				yield put({
					type: "params/setLAR",
					payload: { message: "getMfaRecoveryError" },
				})
				return removeLCS(action)
			default: {
				removeLCS(action)
				yield put({
					type: "params/setLAR",
					payload: {
						message: "successGetMFARecovery",
						data,
					},
				})
			}
		}
	} catch (error) {
		console.warn(error)
	}
}

export function* cancelMFASetup(action) {
	try {
		yield put({ type: "params/setLAR" })
		yield put({
			type: "action/setActionLoader",
			payload: {
				transparency: true,
			},
		})
		addLCS(action)

		const xLsT = yield select((state) => state.auth.xLsToken)
		const token = yield select((state) => state.auth.token.token)
		const payload = { token, xLsT }

		const data = yield call(Api.cancelMFASetup, payload)

		switch (data) {
			case "sessionExpired":
				yield put({ type: "action/setActionLoader" })
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				yield put({ type: "action/setActionLoader" })
				return yield put({ type: "auth/sessionExpired" })
			case true:
				yield put({ type: "action/setActionLoader" })
				return removeLCS(action)
			default: {
				removeLCS(action)

				// If the saga was called with setupLoop, it means it was called after the user refreshed while being on the
				// MFA setup popup. In this precise case, the cancelMFASetup saga was called after the setupMFA received an error
				// in order to allow the user to do the setup. Now that we have cleaned the DB, we can try once again the setup.
				if (action.payload?.setupLoop) {
					return yield put({
						type: "OPTIONS_MFA_SETUP",
					})
				}

				yield put({ type: "action/setActionLoader" })

				return yield put({
					type: "params/setLAR",
					payload: {
						message: "successCancelMFASetup",
						data,
					},
				})
			}
		}
	} catch (error) {
		console.warn(error)
	}
}

export function* removeMFA(action) {
	try {
		yield put({ type: "params/setLAR" })
		yield put({
			type: "action/setActionLoader",
			payload: {
				transparency: true,
			},
		})
		addLCS(action)

		const xLsT = yield select((state) => state.auth.xLsToken)
		const token = yield select((state) => state.auth.token.token)
		const payload = { token, xLsT, code: action?.payload?.code }

		const data = yield call(Api.removeMFA, payload)
		yield put({ type: "action/setActionLoader" })

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				yield put({
					type: "params/setLAR",
					payload: {
						message: "failureMFARemoval",
					},
				})
				return removeLCS(action)
			default: {
				yield put({ type: "auth/setMfaConnection", payload: false })
				const userToken = JSON.parse(localStorage.getItem("token"))
				const newToken = {
					...userToken,
					userSession: {
						...userToken.userSession,
						isMfaConnection: false,
					},
				}

				removeLCS(action)
				localStorage.setItem("token", JSON.stringify(newToken))

				toastr.success(I18n.t("params.MFA.remove.removalSuccess"))
				yield put({
					type: "params/setLAR",
					payload: {
						message: "successMFARemoval",
						data,
					},
				})
			}
		}
	} catch (error) {
		console.warn(error)
	}
}

export default function* paramsSaga() {
	yield takeLeading("OPTIONS_CHANGE_PASS_SAGA", changePass)
	yield takeLeading("OPTIONS_CHANGE_PIN_SAGA", changePin)
	yield takeLeading("OPTIONS_CHECK_PIN_SAGA", checkPin)
	yield takeLeading("OPTIONS_CHANGE_NAME_SAGA", changeName)

	yield takeLeading("OPTIONS_POST_PP_SAGA", postPP)
	yield takeLeading("OPTIONS_EDIT_PP_SAGA", editPP)
	yield takeLeading("OPTIONS_DEL_PP_SAGA", delPP)

	yield takeLeading("OPTIONS_IMPORT_PASS_SAGA", importPass)
	yield takeLeading("OPTIONS_IMPORT_USER_SAGA", importUser)
	yield takeLeading("OPTIONS_IMPORT_GROUPS_USERS_SAGA", importGroupsUsers)
	yield takeLeading("OPTIONS_IMPORT_ARBORESCENSE_SAGA", importArborescence)
	yield takeLeading("OPTIONS_IMPORT_CAT_SAGA", importCat)
	yield takeLeading("OPTIONS_IMPORT_ORG_SAGA", importOrg)
	yield takeLeading("OPTIONS_EXPORT_PASS_OPTI_SAGA", exportPassPersoOpti)
	yield takeLeading("OPTIONS_EXPORT_PASS_SAGA", exportPass)
	yield takeLeading("OPTIONS_EXPORT_ALL_PASS_SAGA", exportAllPass)
	yield takeLeading("OPTIONS_EXPORT_ALL_PASS_FILES_SAGA", exportPassAndFiles)
	yield takeLeading("OPTIONS_EXPORT_USERS_ORGA", exportUsersOrga)

	yield takeLeading("OPTIONS_GET_USER_KEY_SAGA", getUserKey)
	yield takeLeading("OPTIONS_ADD_KEY_SAGA", addUserKey)
	yield takeLeading("OPTIONS_DEL_KEY_SAGA", delUserKey)

	yield takeLeading("OPTIONS_GET_SMTP_SAGA", getSMTP)
	yield takeLeading("OPTIONS_POST_SMTP_SAGA", saveSMTP)
	yield takeLeading("OPTIONS_PUT_SMTP_SAGA", changeSMTP)
	yield takeLeading("OPTIONS_TEST_SMTP_SAGA", testSMTP)

	yield takeLeading("G_CSV_ERROR_SAGA", csvError)

	yield takeLeading("OPTIONS_GET_ADMIN", getAdminData)
	yield takeLeading("OPTIONS_PUT_ADMIN", updateAdminData)
	yield takeLeading("OPTIONS_ADD_WHITELIST_IP", addWhitelistIP)
	yield takeLeading("OPTIONS_DEL_WHITELIST_IP", deleteIp)
	yield takeLeading("OPTIONS_GET_LOCKPASS", getLockpassOpt)
	yield takeLeading("OPTIONS_PUT_LOCKPASS", updateLockpassOtp)
	yield takeLeading("OPTIONS_PP_LOCKPASS", updatePasswordPolicy)
	yield takeLeading("OPTIONS_GET_LOCKTRANSFER", getLocktranferOpt)
	yield takeLeading("OPTIONS_PUT_LOCKTRANSFER", updateLockTransfer)
	yield takeLeading("OPTIONS_GET_LOCKFILES", getLockFilesOpt)
	yield takeLeading("OPTIONS_PUT_LOCKFILES", updateLockFiles)
	yield takeLeading("OPTIONS_GET_MANAGEMENT", getManagementOpt)
	yield takeLeading("OPTIONS_PUT_MANAGEMENT", updateManagement)
	yield takeLeading("OPTIONS_ADD_DOMAINE", addDomaine)
	yield takeLeading("OPTIONS_DEL_DOMAINE", deleteDomaine)

	yield takeLeading("OPTIONS_MFA_SETUP", getMFASetup)
	yield takeLeading("OPTIONS_MFA_CONFIRM", confirmMFA)
	yield takeLeading("OPTIONS_MFA_RECOVERY", getMFARecovery)
	yield takeLeading("OPTIONS_MFA_CANCEL_SETUP", cancelMFASetup)
	yield takeLeading("OPTIONS_MFA_REMOVAL", removeMFA)
	// yield takeLeading("M_GET_USER_SAGA", getUser)
}
