import { toastr } from "react-redux-toastr"
import I18n from "../i18n"
import { handleError, backwardCompApi } from "./generalUtils"

function headers(payload) {
	let a = {
		"Accept-Language": localStorage.getItem("i18nextLng"),
		Authorization: "Bearer " + payload.token,
		"X-Ls-Token": payload.xLsT,
	}

	if (!payload.formData) a = { ...a, "Content-Type": "application/json" }

	return a
}

export const getInternalWhitemark = (payload) => {
	//console.log(payload)

	let responseStatus
	let url = localStorage.getItem("apiUrl") + "whitemark" // + formQuery(payload)

	return fetch(url, {
		method: "GET",
		headers: headers(payload),
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res.ok
			return res
		})
		.then((res) => res.json())
		.then((res) => (!responseStatus ? handleError(res, false) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const getSso = (payload) => {
	//console.log(payload)

	let responseStatus
	let url = localStorage.getItem("apiUrl").replace("api/", "") + "saml2/conf " // + formQuery(payload)

	return fetch(url, {
		method: "GET",
		headers: headers(payload),
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res.ok
			return res
		})
		.then((res) => res.json())
		.then((res) => (!responseStatus ? handleError(res, false) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const delSso = (payload) => {
	////console.log(payload.ids)

	let responseStatus
	let url = localStorage.getItem("apiUrl").replace("api/", "") + "saml2/"

	return fetch(url, {
		method: "DELETE",
		headers: headers(payload),
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res.ok
			return res
		})
		.then((res) => (responseStatus ? res : res.json()))
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const getMeta = (payload) => {
	////console.log(payload.ids)

	let responseStatus
	let url =
		localStorage.getItem("apiUrl").replace("api/", "") +
		"saml2/metadata/info"

	return fetch(url, {
		method: "GET",
		headers: headers(payload),
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res.ok
			return res
		})
		.then((res) => (responseStatus ? res : res.json()))
		.then((res) => (!responseStatus ? handleError(res) : res.json()))
}

export const getSsoCheck = (payload) => {
	// console.log("running getSsoCheck service", payload)

	let responseStatus
	let url =
		localStorage.getItem("apiUrl").replace("api/", "") + "saml2/login/check"
	let networkError = false

	return fetch(url, {
		method: "GET",
		headers: headers(payload),
	})
		.catch((e) => {
			networkError = true
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			if (networkError) throw new Error("Network error")
			responseStatus = res.ok
			return res
		})
		.then((res) => (responseStatus ? res : res.json()))
		.then((res) => (!responseStatus ? handleError(res) : res.json()))
		.catch(() => true)
}

export const ssoUrl = (payload) => {
	//console.log(payload)

	let responseStatus
	let url =
		localStorage.getItem("apiUrl").replace("api/", "") +
		"saml2/xmls/remotes?profile=1"

	return fetch(url, {
		method: "POST",
		headers: headers(payload),
		body: JSON.stringify(payload),
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res?.ok
			return res
		})
		.then((res) => {
			if (res.status === 200) {
				return
			}
			return res.json()
		})
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const ssoXml = (payload) => {
	let responseStatus
	let url = localStorage.getItem("apiUrl").replace("api/", "") + "saml2/xmls"

	const body = new FormData()
	body.append("file", payload.file)

	return fetch(url, {
		method: "POST",
		headers: headers({ ...payload, formData: true }),
		body,
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res?.ok
			return res
		})
		.then((res) => {
			if (!res) {
				return res.json()
			}

			return
		})
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const getSmsQuota = (payload) => {
	//console.log(payload)

	let responseStatus
	let url = localStorage.getItem("apiUrl") + "transfers/quota" // + formQuery(payload)

	return fetch(url, {
		method: "GET",
		headers: headers(payload),
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res.ok
			return res
		})
		.then((res) => res.json())
		.then((res) => (!responseStatus ? handleError(res, false) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const downloadWithToken = (payload) => {
	//console.log(payload)

	let responseStatus
	let url = localStorage.getItem("apiUrl") + "download/generate/token"
	let body = JSON.stringify(payload.data)

	function finalDownload(link) {
		window.location.assign(link)
		return "Success"
	}

	return fetch(url, {
		method: "POST",
		headers: headers(payload),
		body,
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res?.ok
			return res
		})
		.then((res) => res.json())
		.then((res) =>
			!responseStatus ? handleError(res) : finalDownload(res.link),
		) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const checkPin = (payload) => {
	//console.log(payload)

	let responseStatus
	let url = localStorage.getItem("apiUrl") + "users/verifies/pins"
	let body = JSON.stringify(payload.data)

	return fetch(url, {
		method: "POST",
		headers: headers(payload),
		body,
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res?.ok
			return res
		})
		.then((res) => res.json())
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const changeColorWm = (payload) => {
	let responseStatus
	let url = localStorage.getItem("apiUrl") + "whitemarks/1"

	if (backwardCompApi("1.18.5")) {
		url = localStorage.getItem("apiUrl") + "v2/whitemarks"
	}

	const body = new FormData()
	body.append("organizationId", payload.data.organizationId)
	body.append("color1", payload.data.color1)
	body.append("color2", payload.data.color2)
	body.append("color3", payload.data.color3)
	body.append("color4", payload.data.color4)
	body.append("color5", payload.data.color5)
	body.append("isSquareLogo", payload.data.isSquareLogo)

	if (backwardCompApi("1.18.5")) {
		body.append("logoFile", payload.data.logoSave64Slice)
	} else {
		body.append("logo", payload.data.logoSave64Slice)
	}

	return fetch(url, {
		method: "POST",
		headers: headers({ ...payload, formData: true }),
		body,
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res?.ok
			return res
		})
		.then((res) => res.json())
		.then((res) =>
			!responseStatus
				? handleError(
						res,
						true,
						I18n.t("params.whitemark.toastr.internalFailed"),
				  )
				: res,
		) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const changeColorWmExternal = (payload) => {
	// console.log("running changeColorWmExternal service", payload)
	let responseStatus
	let url = localStorage.getItem("apiUrl") + "whitemarks/externals/1"

	if (backwardCompApi("1.18.5")) {
		url = localStorage.getItem("apiUrl") + "v2/whitemarks/externals"
	}

	const body = new FormData()
	body.append("organizationId", payload.data.organizationId)
	body.append("backgroundFile", payload.data.largeSave64) // Background external
	body.append("bgColorClickButton", payload.data.color5) // Color button external
	body.append("bannerFile", payload.data.bannerSave64) // Banner email external
	body.append("language", payload.data.emailLanguage ?? "") // Language email external
	body.append("companyName", payload.data.companyName) // Company name external
	body.append("faviconFile", payload.data.tinySave64) // Favicon external

	return fetch(url, {
		method: "POST",
		headers: headers({ ...payload, formData: true }),
		body,
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res?.ok
			return res
		})
		.then((res) => res.json())
		.then((res) =>
			!responseStatus
				? handleError(
						res,
						true,
						I18n.t("params.whitemark.toastr.externalFailed"),
				  )
				: res,
		) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const patchConnexion = (payload) => {
	let resCode
	let responseStatus
	let url = `${localStorage.getItem("apiUrl")}users/connexion`

	return fetch(url, {
		method: "PATCH",
		headers: headers({ ...payload, isPatch: true }),
		body: JSON.stringify({ connexionNumber: payload.connexionNumber }),
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res?.ok
			resCode = res.status
			return res
		})
		.then((res) => res.json())
		.then((res) => (!responseStatus ? { res, resCode } : res))
}

export const getExternalFile = (payload) => {
	return fetch(payload.url, {
		method: "GET",
		headers: {
			"Content-Type": "application/octet-stream",
			"Access-Control-Allow-Origin": "*",
		},
	})
		.then((res) => res.blob())
		.catch((error) => {
			console.warn("error", error)
			return
		})
}
